import { combineReducers } from "redux";
import auth from "./auth";
import authSignup from "./auth/signup";
import authForgot from "./auth/forgot";
import authReset from "./auth/reset";
import getProfile from "./profile/get";
import updateProfile from "./profile/update";
import deleteProfileImage from "./profile/delete-profile-image";
import socialLogin from "./auth/sociallogin";
import uploadImage from "./upload";
import changePwd from "./auth/change-password";
import modalState from "./project/createmodal";
import getProject from "./project/get";
import getWritingSetting from './profile/writing-settings/get';
import getFolder from './document/get'

const rootReducer = combineReducers({
  auth,
  authSignup,
  authForgot,
  authReset,
  getProfile,
  updateProfile,
  socialLogin,
  uploadImage,
  changePwd,
  modalState,
  deleteProfileImage,
  getProject,
  getWritingSetting,
  getFolder
});

export default rootReducer;
