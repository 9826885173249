import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    user: {},
    error: {},
    message: null,
}

const updateProfileSlice = createSlice({
    name: 'updateProfile',
    initialState,
    reducers: {
        resetData: (state) => {
            state.user = {};
            state.error = {};
            state.message = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.user = {};
            state.error = {};
        },
        updateProfile: (state, action) => {
            state.loading = false;
            state.user = action.payload.data;
            state.message = action.payload.message;
            state.error = {}
        },
        handleError: (state, action) => {
            state.loading = false;
            state.user = {};
            state.error = {code: action.payload?.code || 500, message: action.payload?.message || "Something went wrong!"};
        }
    }
});

export const { setLoading, updateProfile, handleError, resetData } = updateProfileSlice.actions;
export default updateProfileSlice.reducer;