import { createContext, useState } from 'react';


const AuthContext = createContext({ name: '', auth: false });
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({ name: '', auth: false });

    // Login updates the user data with a name parameter
    const login = (name) => {
        setUser((user) => ({
        name: name,
        auth: true,
        }));
    };

    // Logout updates the user data to default
    const logout = () => {
        setUser((user) => ({
        name: '',
        auth: false,
        }));
    };
    return (
      <AuthContext.Provider value={{ user, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
  }
  export default AuthContext;