import {  Route, Redirect } from 'react-router-dom';
import { useContext } from 'react';

import AuthContext from './context/auth';

function AuthRoute({ children, auth, ...rest }) {
    const authData = useContext(AuthContext);
    let isAuthenticated = authData?.user?.auth;
    const token = localStorage.getItem("token");
    if (!isAuthenticated && token) {
        isAuthenticated = true;
    }
    return (
      <Route
        {...rest}
        render={() => {
          return isAuthenticated === true ? children : <Redirect to="/auth/signin" />;
        }}
      />
    );
  }
  
  export default AuthRoute;