import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    token: null,
    user: {},
    error: {},
    message: null,
}

const authSignupSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetData: (state) => {
            state.user = {};
            state.token = null;
            state.message = null;
            state.error = {};
        },
        setLoading: (state) => {
            state.loading = true;
            state.user = {};
            state.error = {};
        },
        signupUser: (state, action) => {
            state.loading = false;
            state.user = action.payload.data.user;
            state.token = action.payload.data.token;
            state.message = action.payload.message;
            state.error = {}
        },
        handleError: (state, action) => {
            state.loading = false;
            state.user = {};
            state.token = null;
            state.error = {code: action.payload?.code || 500, message: action.payload?.message || "Something went wrong!"};
        }
    }
});

export const { setLoading, signupUser, handleError, resetData } = authSignupSlice.actions;
export default authSignupSlice.reducer;