import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const projectModalSlice = createSlice({
  name: "projectmodal",
  initialState,
  reducers: {
    handleModalState: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { handleModalState } = projectModalSlice.actions;
export default projectModalSlice.reducer;
