import * as React from 'react';
const SignIn = React.lazy(() => import('./Auth/SignIn'));
const SignUp = React.lazy(() => import('./Auth/Signup'));
const Forgot = React.lazy(() => import('./Auth/Forgot'));
const ResetPassword = React.lazy(() => import('./Auth/ResetPassword'));
const SocialLogin = React.lazy(() => import('./Auth/Social'));

const route = [
    {
        path: '/auth/signin', exact: true, name: "Sign In", component: SignIn
    },
    {
        path: '/auth/signup', exact: true, name: "Sign Up", component: SignUp
    },
    {
        path: '/auth/forgot', exact: true, name: "Forgot Password", component: Forgot
    },
    {
        path: '/auth/reset-password', exact: true, name: "OTP", component: ResetPassword
    },
    {
        path: '/auth/social', name: "Social Login", component: SocialLogin
    }
]
export default route;