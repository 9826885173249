import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  project: [],
  error: {},
  message: null,
};

const getProjectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.project = [];
      state.error = {};
    },
    getProject: (state, action) => {
      state.loading = false;
      state.project = action.payload.data;
      state.message = action.payload.message;
      state.error = {};
    },
    handleError: (state, action) => {
      state.loading = false;
      state.project = [];
      state.error = {
        code: action.payload?.code || 500,
        message: action.payload?.message || "Something went wrong!",
      };
    },
  },
});

export const { setLoading, getProject, handleError } = getProjectSlice.actions;
export default getProjectSlice.reducer;
