import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0E55CF',
      success: "#5ABF26",
      warning: "#E48E24"
    },
    secondary: {
      main: green[500],
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '4px 0px 0px 0px',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: '#FFFFFF !important',
          borderRadius: '5px',
          fontWeight: '700 !important',
          fontSize: '18px !important',
          lineHeight: '30px !important',
          marginBottom: '8px',
          marginTop: '0px',
          // padding: '9px 15px 8px !important' ,
          color: '#041636 !important',
        }
      }
    }
  }
});

export default theme;