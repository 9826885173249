import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  folders: [],
  documents: [],
  error: {},
  message: null,
};

const getFolderSlice = createSlice({
  name: "Folder",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      //   state.folders = [];
      //   state.documents = [];
      state.error = {};
    },
    getFolder: (state, action) => {
      state.loading = false;
      state.folders = action.payload.data;
      state.message = action.payload.message;
      state.error = {};
    },
    getDocument: (state, action) => {
      state.loading = false;
      state.documents = action.payload.data;
      state.message = action.payload.message;
      state.error = {};
    },
    handleFolderError: (state, action) => {
      state.loading = false;
      state.folders = [];
      state.error = {
        code: action.payload?.code || 500,
        message: action.payload?.message || "Something went wrong!",
      };
    },
    handleDocumentError: (state, action) => {
      state.loading = false;
      state.documents = [];
      state.error = {
        code: action.payload?.code || 500,
        message: action.payload?.message || "Something went wrong!",
      };
    },
  },
});

export const {
  setLoading,
  getFolder,
  getDocument,
  handleFolderError,
  handleDocumentError,
} = getFolderSlice.actions;
export default getFolderSlice.reducer;
