import * as React from "react";
const Dashboard = React.lazy(() => import("./App/Dashboard"));
const Profile = React.lazy(() => import("./App/profile"));
const AddProject = React.lazy(() => import("./App/Project/addProject"));
const Document = React.lazy(() => import("./App/Project/document"));

const routes = [
  {
    path: "/app/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  { path: "/app/profile", exact: true, name: "Profile", component: Profile },
  { path: "/app/project", exact: true, name: "Profile", component: AddProject },
  { path: "/app/document/:id", exact: true, name: "Profile", component: Document },
];

export default routes;
