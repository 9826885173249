import * as React from "react";
import { lazy, Suspense, useState, useEffect } from "react";
import { Route, useLocation, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";
import authRoute from "../route";
import routes from "../routes";
import Config from "../config";
import theme from "../theme/theme";
import { store } from "../redux/store";
import ErrorBoundary from "../error";
import AuthRoute from "../authRoute";
import { AuthProvider } from "../context/auth";

const AppLayout = lazy(() => import("./layout/AppLayout"));

const App = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {}, [isAuthenticated]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);
  return (
    <>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <ScrollToTop>
                <Suspense fallback={<Loader />}>
                  <Route path={authRoute.map((x) => x.path)}>
                    <Switch location={location} key={location.pathname}>
                      {authRoute.map((route, index) => {
                        return route.component ? (
                          <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => <route.component {...props} />}
                          />
                        ) : null;
                      })}
                    </Switch>
                  </Route>
                  <AuthRoute
                    auth={isAuthenticated}
                    path={routes.map((x) => x.path)}
                  >
                    <AppLayout />
                  </AuthRoute>
                  <Route path={"/"} exact>
                    <Redirect to={Config.defaultPath} />
                  </Route>
                </Suspense>
              </ScrollToTop>
            </ErrorBoundary>
            <div className="backdrop" />
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </>
  );
};
export default App;
