import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: {},
    message: null,
}

const deleteProfileImageSlice = createSlice({
    name: 'deleteProfileImage',
    initialState,
    reducers: {
        resetData: (state) => {
            state.error = {};
            state.message = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.error = {};
        },
        deleteProfileImage: (state, action) => {
            state.loading = false;
            state.message = action.payload.message;
            state.error = {}
        },
        handleError: (state, action) => {
            state.loading = false;
            state.error = {code: action.payload?.code || 500, message: action.payload?.message || "Something went wrong!"};
        }
    }
});

export const { setLoading, deleteProfileImage, handleError, resetData } = deleteProfileImageSlice.actions;
export default deleteProfileImageSlice.reducer;