import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    personaData: {},
    readabalitydata: {},
    tonesData: {},
    targetAudianceData: {},
    error: {},
    message: null,
}

const getWritingSettingSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetData: (state) => {
            state.data = {};
            state.personaData = {};
            state.readabalitydata = {};
            state.tonesData = {};
            state.targetAudianceData = {};
            state.message = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.personaData = {};
            state.readabalitydata = {};
            state.tonesData = {};
            state.targetAudianceData = {};
            state.error = {};
        },
        getPersonaData: (state, action) => {
            state.loading = false;
            state.personaData = action.payload.data;
            state.message = action.payload.message;
            state.error = {}
        },
        getReadabalityData: (state, action) => {
            state.loading = false;
            state.readabalitydata = action.payload.data;
            state.message = action.payload.message;
            state.error = {}
        },
        getTonesData: (state, action) => {
            state.loading = false;
            state.tonesData = action.payload.data;
            state.message = action.payload.message;
            state.error = {}
        },
        getTargetAudianceData: (state, action) => {
            state.loading = false;
            state.targetAudianceData = action.payload.data;
            state.message = action.payload.message;
            state.error = {}
        },
        handleError: (state, action) => {
            state.loading = false;
            state.personaData = {};
            state.readabalitydata = {};
            state.tonesData = {};
            state.targetAudianceData = {};
            state.error = {code: action.payload?.code || 500, message: action.payload?.message || "Something went wrong!"};
        }
    }
});

export const { setLoading, getPersonaData, getReadabalityData, getTonesData, getTargetAudianceData, handleError, resetData } = getWritingSettingSlice.actions;
export default getWritingSettingSlice.reducer;