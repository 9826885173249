import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    passwordChanged: false,
    error: {},
    message: null,
}

const changePasswordSlice = createSlice({
    name: 'updateProfile',
    initialState,
    reducers: {
        resetData: (state) => {
            state.passwordChanged= false;
            state.error = {};
            state.message = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.passwordChanged = false;
            state.error = {};
        },
        changePassword: (state, action) => {
            state.loading = false;
            state.passwordChanged = true;
            state.message = action.payload.message;
            state.error = {}
        },
        handleError: (state, action) => {
            state.loading = false;
            state.passwordChanged = false;
            state.error = {code: action.payload?.code || 500, message: action.payload?.message || "Something went wrong!"};
        }
    }
});

export const { setLoading, changePassword, handleError, resetData } = changePasswordSlice.actions;
export default changePasswordSlice.reducer;